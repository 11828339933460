import { Component } from '@angular/core';
import { Crumb } from '../common/bread-crumbs/crumb';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import * as moment from 'moment';
import { FhdHierarchyService } from './service/fhd-hierarchy.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  of,
  takeUntil,
  throwError,
} from 'rxjs';
import { AlertService } from '../core/alerts/alerts.service';
import {
  IFHDHierarchy,
  IFHDHierarchyGroup,
  IFHDHierarchyItem,
} from './model/fhd-hierarchy';
import { COLORS } from './constants/color';
import { AuthService } from '../core/auth/auth.service';
import { FormControl } from '@angular/forms';
import { Base } from '../common/base.service';

@Component({
  selector: 'giq-fhd-hierarchy',
  templateUrl: './fhd-hierarchy.component.html',
  styleUrls: ['./fhd-hierarchy.component.scss'],
})
export class FhdHierarchyComponent extends Base {
  public crumbs: Crumb[] = [
    { name: 'Data Exchange', url: '/exchange' },
    { name: 'FHD Hierarchy', url: undefined },
  ];
  reportingDate = moment().format('MM/DD/YYYY');
  pushIndex!: number;
  agents: IFHDHierarchyItem[] = [];
  isCopyEnabled = false;
  agencyPosition: IFHDHierarchyGroup[] = [];
  aaPosition: IFHDHierarchyGroup[] = [];
  abPosition: IFHDHierarchyGroup[] = [];
  agentPosition: IFHDHierarchyGroup[] = [];
  selectedCopyItem!: IFHDHierarchyItem;
  selectedAgencyId!: string;
  existingAgentsNumber: string[] = ["73252"];
  fhdHierarchyDetails!: IFHDHierarchy;
  isAgentsDisabled: boolean = true;
  isAgencyPositionDisabled: boolean = true;
  isAAPositionDisabled: boolean = true;
  isABPositionDisabled: boolean = true;
  isAgentsPositionDisabled: boolean = true;
  userName: string = '';
  loggedAgentName: string = '';
  isUserExist: boolean = false;
  isAdminUser: boolean = false;
  searchControl = new FormControl('');
  allAgents: IFHDHierarchyItem[] = [];
  loader = 2;

  constructor(private fhdHierarchyService: FhdHierarchyService,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private authService: AuthService) {
    super();
  }

  ngOnInit() {
    this.spinner.show();
    this.authService.getUserInfo().subscribe((info) => {
      this.userName = info.name;
      this.loggedAgentName = `${info.name}, ${info.lastName}`;
      this.isAdminUser = info.frontActions.includes('fhdhierarchy_admin');
    });
    this.fhdHierarchyService
      .getAgents()
      .pipe(
        catchError((err: any) => {
          if (err.errorMessage) {
            this.alertService.warning(err.errorMessage);
          } else {
            this.alertService.warning('There was an error');
          }
          return throwError(() => err);
        }),
        finalize(() => {
          this.loader--;
          if (this.loader === 0) {
            this.spinner.hide();
          }
        })
      )
      .subscribe((response) => {
        this.agents = response;
        this.allAgents = response;
      });

    this.getFHDHierarchy();
    this.searchControl.valueChanges.pipe(takeUntil(this.serviceDestroyed$), debounceTime(500), distinctUntilChanged(),
    ).subscribe(searchValue => {
      if (!searchValue) {
        this.agents = [...this.allAgents];
      }
      else {
        this.agents = this.allAgents.filter(agent => {
          return agent.agentName.toLowerCase().includes(searchValue.toLowerCase()) || String(agent.agentNumber).includes(searchValue);
        });
      }
    });
  }
  getFHDHierarchy(isPageLoad = true) {
    this.fhdHierarchyService.getFHDHierarchy().pipe(
      catchError((err: any) => {
        if (err.errorMessage) {
          this.alertService.warning(err.errorMessage);
        } else {
          this.alertService.warning('There was an error');
        }
        return throwError(() => err);
      }),
      finalize(() => {
        this.loader--;
        if (this.loader === 0) {
          this.spinner.hide();
        }
      })
    ).subscribe(response => {
      this.fhdHierarchyDetails = response;
      this.existingAgentsNumber = Object.values(response)
        .flat()
        .map((item) => {
          if (item.agentName === this.loggedAgentName && !this.isAdminUser) {
            this.isUserExist = true;
            if (item.position === 'agency') {
              this.isAgentsDisabled = false;
              this.isAgencyPositionDisabled = false;
              this.isAAPositionDisabled = false;
              this.isABPositionDisabled = false;
              this.isAgentsPositionDisabled = false;
            }
            else if (item.position === 'aa') {
              this.isAgentsDisabled = false;
              this.isABPositionDisabled = false;
              this.isAgentsPositionDisabled = false;
            }
          }
          return item.agentNumber
        });

      if (isPageLoad) {
        this.onRecallClick();
        if (!this.isUserExist && !this.isAdminUser) {
          this.alertService.warning('The user does not exist');
        }
        if (this.isAdminUser) {
          this.isAgentsDisabled = false;
          this.isAgencyPositionDisabled = false;
          this.isAAPositionDisabled = false;
          this.isABPositionDisabled = false;
          this.isAgentsPositionDisabled = false;
        }
      }
    });
  }

  onRecallClick() {
    this.agencyPosition = [];
    this.aaPosition = [];
    this.abPosition = [];
    this.agentPosition = [];
    this.fhdHierarchyDetails.agencyPosition.forEach((agecyItem) => {
      const parentColor = this.getColor(this.agencyPosition.length);
      this.updatePostion(
        this.agencyPosition,
        '0',
        '0',
        this.agencyPosition.length,
        parentColor,
        [agecyItem]
      );
      const relatedAaItem = this.fhdHierarchyDetails.aaPosition.filter(
        (aaItem) => aaItem.parentId === agecyItem.agentNumber
      );
      this.updatePostion(
        this.aaPosition,
        agecyItem.agentNumber,
        agecyItem.agentNumber,
        this.aaPosition.length,
        parentColor,
        relatedAaItem
      );
      relatedAaItem.forEach((relatedAa) => {
        const relatedAbItem = this.fhdHierarchyDetails.abPosition.filter(
          (abItem) => abItem.parentId === relatedAa.agentNumber
        );
        this.updatePostion(
          this.abPosition,
          agecyItem.agentNumber,
          relatedAa.agentNumber,
          this.abPosition.length,
          parentColor,
          relatedAbItem
        );
        relatedAbItem.forEach((relatedAb) => {
          const relatedAgentItem =
            this.fhdHierarchyDetails.agentsPosition.filter(
              (agentItem) => agentItem.parentId === relatedAb.agentNumber
            );
          this.updatePostion(
            this.agentPosition,
            agecyItem.agentNumber,
            relatedAb.agentNumber,
            this.agentPosition.length,
            parentColor,
            relatedAgentItem
          );
        });
      });
      setTimeout(() => {
        this.updateHeight(String(agecyItem.agentNumber));
      });
    });
    this.updatePostion(
      this.agencyPosition,
      '0',
      '0',
      this.agencyPosition.length,
      this.getColor(this.agencyPosition.length)
    );
  }

  agencyDrop(event: CdkDragDrop<IFHDHierarchyItem[]>) {
    if (
      event.container.data?.length ||
      event.previousContainer.id !== 'agent-list'
    ) {
      return;
    }
    const movedAgentNumber =
      event.previousContainer.data[event.previousIndex].agentNumber;
    this.drop(event);
    this.updatePostion(
      this.aaPosition,
      movedAgentNumber,
      movedAgentNumber,
      this.aaPosition.length,
      this.getParentColor(movedAgentNumber)
    );

    this.updatePostion(
      this.agencyPosition,
      '0',
      '0',
      this.agencyPosition.length,
      this.getColor(this.agencyPosition.length)
    );
  }

  updateParentId(droppedList: IFHDHierarchyItem[], parentId: string) {
    droppedList.map((item) => {
      if (!item.parentId) {
        item.parentId = parentId;
      }
      return item;
    });
  }

  aaDrop(event: CdkDragDrop<IFHDHierarchyItem[]>) {
    if (event.previousContainer.id !== 'agent-list') {
      return;
    }
    const agencyId = event.container.id.split('-').pop();
    const movedAgentNumber =
      event.previousContainer.data[event.previousIndex].agentNumber;
    this.drop(event);
    const pushIndex = this.getPushIndex(this.aaPosition, movedAgentNumber);
    this.updatePostion(
      this.abPosition,
      String(agencyId),
      movedAgentNumber,
      pushIndex,
      this.getParentColor(String(agencyId))
    );
  }

  abDrop(event: CdkDragDrop<IFHDHierarchyItem[]>) {
    if (event.previousContainer.id !== 'agent-list') {
      return;
    }
    const agencyId = event.container.id.split('-').pop();
    const movedAgentNumber =
      event.previousContainer.data[event.previousIndex].agentNumber;
    this.drop(event);
    const pushIndex = this.getPushIndex(this.abPosition, movedAgentNumber);

    this.updatePostion(
      this.agentPosition,
      String(agencyId),
      movedAgentNumber,
      pushIndex,
      this.getParentColor(String(agencyId))
    );
  }

  agentDrop(event: CdkDragDrop<IFHDHierarchyItem[]>) {
    if (event.previousContainer.id !== 'agent-list') {
      return;
    }
    this.drop(event);
  }

  drop(event: CdkDragDrop<IFHDHierarchyItem[]>) {
    const removedAgentNumber =
      event.previousContainer.data[event.previousIndex].agentNumber;
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    let agencyId = event.container.id.split('-').pop();
    if (event.container.id === 'agent-list' && event.previousContainer.id != 'agent-list') {
      this.agents = this.agents.filter((item, index, self) => {
        return index === self.findIndex(selfItem => selfItem.agentNumber === item.agentNumber);
      });
      agencyId = event.previousContainer.id.split('-').pop();
      if (event.previousContainer.id.includes('agencyList')) {
        this.agencyPosition = this.agencyPosition.filter(
          (value) => value.items.length !== 0
        );
        this.updatePostion(
          this.agencyPosition,
          '0',
          '0',
          this.agencyPosition.length,
          this.getColor(this.agencyPosition.length)
        );
        this.removeAa(removedAgentNumber);
      } else if (event.previousContainer.id.includes('aaList')) {
        this.removeAb(removedAgentNumber);
      } else if (event.previousContainer.id.includes('abList')) {
        this.removeAgent(removedAgentNumber);
      }
    }
    setTimeout(() => {
      this.updateHeight(String(agencyId));
    });
  }

  removeAa(removedAgentNumber: string) {
    this.aaPosition = this.aaPosition.filter((value) => {
      if (value.parentId === removedAgentNumber) {
        value.items.forEach((item) => {
          this.removeAb(item.agentNumber);
        });
        this.agents.unshift(...value.items);
        return false;
      } else {
        return true;
      }
    });
  }

  removeAb(removedAgentNumber: string) {
    this.abPosition = this.abPosition.filter((value) => {
      if (value.parentId === removedAgentNumber) {
        value.items.forEach((item) => {
          this.removeAgent(item.agentNumber);
        });
        this.agents.unshift(...value.items);
        return false;
      } else {
        return true;
      }
    });
  }

  removeAgent(removedAgentNumber: string) {
    this.agentPosition = this.agentPosition.filter((value) => {
      if (value.parentId === removedAgentNumber) {
        this.agents.unshift(...value.items);
        return false;
      } else {
        return true;
      }
    });
  }

  updatePostion(
    data: IFHDHierarchyGroup[],
    agencyId: string,
    parentId: string,
    pushIndex: number,
    color: string,
    items?: IFHDHierarchyItem[]
  ) {
    data.splice(pushIndex, 0, {
      items: items ?? [],
      agencyId,
      parentId,
      color,
    });
  }

  getPushIndex(data: IFHDHierarchyGroup[], agentNumber: string) {
    return data
      .flatMap((value: any) =>
        Array.isArray(value.items) && value.items.length === 0
          ? [null]
          : value.items
      )
      .findIndex((item: any) => agentNumber === item?.agentNumber);
  }

  getParentColor(agentNumber: string) {
    for (const position of this.agencyPosition) {
      if (position.items.some((item) => item.agentNumber === agentNumber)) {
        return position.color;
      }
    }
    return '#555';
  }

  updateHeight(agencyId: string) {
    if (agencyId) {
      this.updateGroupHeight(this.abPosition, agencyId, 'agentList', 'abList');
      this.updateGroupHeight(this.aaPosition, agencyId, 'abList', 'aaList');

      let index = 0;
      for (const position of this.agencyPosition) {
        if (position.items.some((item) => item.agentNumber === agencyId)) {
          const height = document.getElementById(
            `aaList-${agencyId}-${agencyId}`
          )?.offsetHeight;
          const element = document.getElementById(
            `agencyList-${index}`
          ) as HTMLElement;

          if (element && height) {
            element.style.marginBottom = `${height - element.offsetHeight}px`;
          }
          break;
        }
        index++;
      }
    }
  }

  updateGroupHeight(
    data: IFHDHierarchyGroup[],
    agencyId: string,
    childKey: string,
    parentKey: string
  ) {
    data.forEach((value) => {
      if (value.agencyId === agencyId) {
        value.items.forEach((item, index) => {
          const height = document.getElementById(
            `${childKey}-${item.agentNumber}-${value.agencyId}`
          )?.offsetHeight;
          const element = document.getElementById(
            `${parentKey}-${value.parentId}-${value.agencyId}`
          )?.children?.[index] as HTMLElement;

          const adjustment = index === value.items.length - 1 ? 0 : 18;
          if (element && height) {
            element.style.marginBottom = `${height - element.offsetHeight + adjustment
              }px`;
          }
        });
      }
    });
  }

  updateCopyItemState(item: IFHDHierarchyItem, agencyId: string) {
    this.selectedCopyItem = item;
    this.isCopyEnabled = true;
    this.selectedAgencyId = agencyId;
  }

  copyToAgents() {
    this.isCopyEnabled = false;
    const copyPostion = this.agentPosition.find(
      (value) => value.agencyId == this.selectedAgencyId
    );
    if (copyPostion) {
      if (copyPostion.items.includes(this.selectedCopyItem)) {
        this.alertService.warning('The agent cannot duplicate to agents position');
      }
      else {
        copyPostion.items.unshift(this.selectedCopyItem);
      }
      setTimeout(() => {
        this.updateHeight(String(this.selectedAgencyId));
      });
    } else {
      this.alertService.warning('AB position related agency is not available');
    }
  }

  onSubmitClick() {
    this.spinner.show();
    const payload = [
      ...this.updateParentAndAgencyId(this.agencyPosition, 'agency'),
      ...this.updateParentAndAgencyId(this.aaPosition, 'aa'),
      ...this.updateParentAndAgencyId(this.abPosition, 'ab'),
      ...this.updateParentAndAgencyId(this.agentPosition, 'agent'),
    ];
    const addedHierarchies = payload.filter(
      (item) => !this.existingAgentsNumber.includes(item.agentNumber)
    );
    const deletedHierarchies = this.existingAgentsNumber.filter(
      (agentNumber) => !payload.find((value) => value.agentNumber == agentNumber)
    );
    this.fhdHierarchyService.updateFHDHierarchy(addedHierarchies, deletedHierarchies)
      .pipe(
        catchError((err: any) => {
          if (err.errorMessage) {
            this.alertService.warning(err.errorMessage);
          } else {
            this.alertService.warning('There was an error');
          }
          return throwError(() => err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(() => {
        this.getFHDHierarchy(false);
        this.alertService.success('Hierarchy Details Saved');
      });
  }

  updateParentAndAgencyId(data: IFHDHierarchyGroup[], position: string) {
    return data.reduce((result, value) => {
      const updatedDetails = [...value.items].map((item) => {
        return {
          ...item,
          parentId: value.parentId,
          agencyId: value.agencyId,
          position,
        };
      }) as IFHDHierarchyItem[];
      return result.concat(updatedDetails);
    }, [] as IFHDHierarchyItem[]);
  }

  getColor(index: number) {
    const totalColors = COLORS.length;
    return COLORS[index % totalColors];
  }
}
