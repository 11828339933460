import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { LottieModule } from 'ngx-lottie';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HighlightPipe } from '../core/highlight.pipe';
import { PascalCasePipe } from '../core/pascal-case.pipe';
import { AutoResizeDirective } from './auto-resize/auto-resize.directive';
import { BreadCrumbsComponent } from './bread-crumbs/bread-crumbs.component';
import { ChipsListComponent } from './chips-list/chips-list.component';
import { ColorValidationDirective } from './color-validation/color-validation.directive';
import { TouchedValidationDirective } from './color-validation/touched-validation.directive';
import { DividerComponent } from './divider/divider.component';
import { FavoriteAppComponent } from './favorite-app/favorite-app.component';
import { InteractionDirective } from './interaction/interaction.directive';
import { LoadingComponent } from './loading/loading.component';
import { ProblemWindowComponent } from './report-problem/problem-window/problem-window.component';
import { ReportProblemComponent } from './report-problem/report-problem.component';
import { SmallLoadComponent } from './small-load/small-load.component';
import { DragAndDropDirective } from './drag-and-drop/drag-and-drop.directive';
import { TruncatePipe } from '../core/truncate.pipe';

export const DateFormats = {
  parse: {
    dateInput: ['YYYY-MM-DD'],
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const downloadURI = (url: string, filename: string) => {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .then()
    .catch(console.error);
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatBadgeModule,
    MatMenuModule,
    MatTooltipModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgxSpinnerModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatCheckboxModule,
    MatButtonModule,
    RouterModule,
    MatExpansionModule,
    FormsModule,
    LottieModule,
    DragDropModule,
    MatSlideToggleModule,
    MatCardModule,
    MatRadioModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatPaginatorModule,
  ],
  exports: [
    InteractionDirective,
    LoadingComponent,
    SmallLoadComponent,
    DividerComponent,
    BreadCrumbsComponent,
    FavoriteAppComponent,
    MatToolbarModule,
    MatIconModule,
    MatBadgeModule,
    MatMenuModule,
    MatTooltipModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgxSpinnerModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatTabsModule,
    MatCheckboxModule,
    MatButtonModule,
    ColorValidationDirective,
    TouchedValidationDirective,
    DividerComponent,
    MatExpansionModule,
    FormsModule,
    HighlightPipe,
    PascalCasePipe,
    LottieModule,
    MatListModule,
    ReportProblemComponent,
    AutoResizeDirective,
    DragDropModule,
    MatSlideToggleModule,
    MatCardModule,
    MatRadioModule,
    ChipsListComponent,
    MatChipsModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    DragAndDropDirective,
    TruncatePipe,    
  ],
  declarations: [
    InteractionDirective,
    AutoResizeDirective,
    LoadingComponent,
    BreadCrumbsComponent,
    FavoriteAppComponent,
    ColorValidationDirective,
    TouchedValidationDirective,
    DividerComponent,
    SmallLoadComponent,
    HighlightPipe,
    PascalCasePipe,
    ReportProblemComponent,
    ProblemWindowComponent,
    ChipsListComponent,
    DragAndDropDirective,
    TruncatePipe,
  ],
  providers: [],
})
export class GiqCommonModule {
  static forRoot(): ModuleWithProviders<GiqCommonModule> {
    return {
      ngModule: GiqCommonModule,
      providers: [
        {
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: DateFormats },
      ],
    };
  }
}
