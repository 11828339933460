import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthConfig, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import {
  Observable,
  asyncScheduler,
  from,
  map,
  observeOn,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { authCodeFlowConfig, environment } from 'src/environments/environment';
import { UserInfo } from '../state/user/user-info';
import {
  isLoggingOut,
  loggingOut,
  setUserAuthenticated,
  setUserInfo,
  userInfo$,
} from '../state/user/user.store';
import { IAuthService } from './iauth.service';

@Injectable()
export class AuthService implements IAuthService {
  private refreshing = false;
  private allowRefresh = true;
  public currentConfig: AuthConfig | null = null;

  constructor(
    private oauthService: OAuthService,
    private httpClient: HttpClient
  ) {
    this.currentConfig = authCodeFlowConfig;
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.clearHashAfterLogin = true;
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    this.oauthService.events.subscribe((x) => {
      if (x?.type === 'token_refresh_error') {
        console.log('Error refreshing token');
        this.allowRefresh = false;
      }
    });
  }

  public setOAuthConfiguration(_: string): void {
    this.currentConfig = authCodeFlowConfig;
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.clearHashAfterLogin = true;
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
  }

  public login(state: string): void {
    localStorage.clear();
    this.oauthService.initCodeFlow(state);
    this.oauthService.tryLogin();
  }

  public isTokenExpired(): boolean {
    let expiration = this.oauthService.getAccessTokenExpiration();
    const now = new Date();
    const expired = expiration == null || new Date(expiration) < now;
    return expired;
  }

  public checkSession(): boolean {
    let validToken = this.oauthService.getAccessToken();
    if (validToken != null) {
      let expiration = this.oauthService.getAccessTokenExpiration();
      if (expiration != null) {
        if (expiration > Date.now()) {
          setUserAuthenticated(true);
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    return false;
  }

  public refresh(): void {
    if (this.allowRefresh) {
      let expiration = this.oauthService.getAccessTokenExpiration();
      let validAccessToken = this.oauthService.hasValidAccessToken();
      const now = new Date().getTime();
      const difference = (expiration ?? 0) - now;
      let inRenewalTime = difference > 5000 && difference < 600000;
      console.log(difference);
      if (inRenewalTime && this.refreshing == false && validAccessToken) {
        this.refreshing = true;
        console.log('calling Renew', difference);
        this.oauthService
          .refreshToken()
          .then((x) => {
            setTimeout(() => {
              this.refreshing = false;
            }, 1000);
          })
          .finally(() => {
            setTimeout(() => {
              this.refreshing = false;
            }, 1000);
          });
      }
    }
  }

  public getToken(): Observable<{
    state: string | undefined;
    validToken: boolean;
  }> {
    return from(this.oauthService.tryLogin()).pipe(
      observeOn(asyncScheduler),
      map(() => {
        let token = this.oauthService.getAccessToken();
        let validToken = token != null && token != '';
        let state = this.oauthService.state;
        return { state, validToken };
      })
    );
  }

  private getUserInfo$: Observable<UserInfo> = this.httpClient
    .get<UserInfo>(environment.apiUrl + '/auth/user-info')
    .pipe(
      tap((info: UserInfo) => {
        setUserInfo(info);
      }),
      shareReplay()
    );

  public getUserInfo(): Observable<UserInfo> {
    return userInfo$.pipe(
      switchMap((info: UserInfo | undefined) => {
        if (info != null) {
          return of(info);
        }
        return this.getUserInfo$;
      })
    );
  }

  public getSchema(): string {
    return 'Bearer';
  }

  public getAccessToken(): string | null {
    if (this.oauthService.hasValidAccessToken()) {
      return this.oauthService.getAccessToken();
    }
    return null;
  }

  public logout(): void {
    if (!isLoggingOut()) {
      try {
        loggingOut();
        this.oauthService.revokeTokenAndLogout();
        setUserAuthenticated(false);
      } catch (e) {
        sessionStorage.clear();
        localStorage.clear();
      }
    }
  }
}
