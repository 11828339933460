import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { withErrorMapping } from '../../core/errors/with-error-mapping';
import { FHDReporteeDetail, FHDTaskItem } from '../model/fhd-input-form';

@Injectable({
  providedIn: 'root'
})
export class FhdInputFormService {
  baseURL = `${environment.apiUrl}`;   
  constructor(private httpClient: HttpClient) { }

  getTasks(userName: string) {
    return this.httpClient
      .get<FHDTaskItem[]>(
        `${this.baseURL}/statsinputform/optionaluserevents` , this.getQueryParam(userName)
      )
      .pipe(withErrorMapping);
  }

  updateTasks(fhdTaskItem: FHDTaskItem[]) {    
    return this.httpClient
      .put<FHDTaskItem[]>(
        `${this.baseURL}/statsinputform/update`,  fhdTaskItem 
      )
      .pipe(withErrorMapping);
  }

  getWeekTimesheet(userName: string, dateRange: string) {
    return this.httpClient
    .get<FHDTaskItem[]>(
      `${this.baseURL}/statsinputform/statsinputform`, this.getQueryParam(userName, dateRange)
    )
    .pipe(withErrorMapping);
  }

  getReportees(dateRange: string) {
    return this.httpClient
    .get<FHDReporteeDetail[]>(
      `${this.baseURL}/statsinputform/reportees`, this.getQueryParam('', dateRange)     
    )
    .pipe(withErrorMapping);
  }

  updateOptionalTasks(userName: string | undefined, fhdTaskItem: FHDTaskItem[]) {    
    return this.httpClient
      .put<FHDTaskItem[]>(
        `${this.baseURL}/statsinputform/updateoptionaluserevents?username=${userName}`,  fhdTaskItem 
      )
      .pipe(withErrorMapping);
  }

  getQueryParam(userName: string, dateRange?: string) {
    let queryParams = new HttpParams({});   
    if (userName) {
      queryParams = queryParams.append('username', userName);
    }
    if (dateRange) {
      const [startDate, endDate] = dateRange.split('to');
      queryParams = queryParams.append('startDate', startDate);
      queryParams = queryParams.append('endDate', endDate);
    }    
    return { params: queryParams };
  }
}
