export const FHDTask = [
    {
        key: 'start_time',
        label: 'Start Time'
    },
    {
        key: 'stop_time',
        label: 'Stop Time'
    },
    {
        key: 'hours',
        label: 'Hours'
    },
    {
        key: 'contacts',
        label: 'Contacts'
    },
    {
        key: 'intros',
        label: 'Intros'
    },
    {
        key: 'demos',
        label: 'Demos'
    },
    {
        key: 'families_protected',
        label: 'Families Protected'
    },
    {
        key: 'two_out_of_three',
        label: '2 out of 3'
    },
    {
        key: 'policies_sold',
        label: 'Policies Sold'
    },
    {
        key: 'gap',
        label: 'GAP'
    },
    {
        key: 'rgap',
        label: 'RGAP'
    },
    {
        key: 'total_gap',
        label: 'Total GAP'
    },
    {
        key: 'appointments_set',
        label: 'Appointments Set'
    },
    {
        key: 'referrals',
        label: 'Referrals'
    },
    {
        key: 'video_links_sent',
        label: 'Video Links Sent'
    }
]

export const FHDTask2OutOf3Days: any = {
    0: 'ZERO',
    1: 'ONE',
    2: 'TWO',
    3: 'THREE',
    4: 'FOUR',
    5: 'FIVE',
    6: 'SIX',
    7: 'SEVEN',
}


