import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import {
  ADMIN,
  AGENT_AUTOMATION,
  AGENT_AUTOMATION_MODIFY,
  AGENT_AUTOMATION_SAVE,
  BANK_COMPLIANCE,
  CHATBOT_CUSTOMESERVICE,
  CHATBOT_DOCUMENT,
  CHATBOT_LND_AGENT,
  CHATS,
  CLAIM_AUDIT_LOGS,
  CUSTOMER_CARE,
  EXTRACTS,
  LEGAL_COMPLAINTS,
  LEGAL_COMPLAINTS_ADMIN,
  LEGAL_EXPENSE,
  LEGAL_EXPENSE_CREATE_CASE,
  LEGAL_EXPENSE_MODIFY_CASE,
  LEGAL_EXPENSE_CREATE_EXPENSE,
  LEGAL_EXPENSE_MODIFY_EXPENSE,
  PRODUCTION_TASK,
  PRODUCTION_TASK_CREATE,
  PRODUCTION_TASK_MODIFY,
  PRODUCTION_TASK_SUPERVISOR,
  RECORD_CATALOG,
  SALESBOARD,
  UNDERWRITING,
  WORKSITE_TRACKER,
  WORKSITE_TRACKER_VOID,
  FHDHIERARCHY,
  OUTAGE_TRACKER,
  FHDINPUTFORM,
  CLAIM_AUDIT_LOGS_MANAGER,
} from 'src/app/constants/permissions';
import { environment } from 'src/environments/environment';
import { UserInfo } from '../state/user/user-info';
import {
  loggingOut,
  setUserAuthenticated,
  setUserInfo,
} from '../state/user/user.store';
import { IAuthService } from './iauth.service';

@Injectable()
export class LocalAuthService implements IAuthService {
  constructor(private router: Router) {}
  isTokenExpired(): boolean {
    return false;
  }

  setOAuthConfiguration(company: string): void {}

  login(state: string): void {
    alert('loging in');
    this.router.navigateByUrl('/login/callback');
  }

  checkSession(): boolean {
    setUserAuthenticated(true);
    return true;
  }

  getToken(): Observable<{ state: string | undefined; validToken: boolean }> {
    return of({
      state: environment.defaultRoute,
      validToken: true,
    });
  }

  public refresh(): void {
    console.log('refreshing' + new Date());
  }

  getUserInfo(): Observable<UserInfo> {
    const info: UserInfo = {
      name: 'Benjamin',
      lastName: 'Sandoval',
      email: 'devtest@test.com',
      userName: 'devtest',
      frontActions: [
        CUSTOMER_CARE,
        AGENT_AUTOMATION,
        AGENT_AUTOMATION_SAVE,
        AGENT_AUTOMATION_MODIFY,
        WORKSITE_TRACKER,
        EXTRACTS,
        RECORD_CATALOG,
        WORKSITE_TRACKER_VOID,
        BANK_COMPLIANCE,
        CLAIM_AUDIT_LOGS,
        CLAIM_AUDIT_LOGS_MANAGER,
        PRODUCTION_TASK_MODIFY,
        PRODUCTION_TASK,
        PRODUCTION_TASK_CREATE,
        PRODUCTION_TASK_SUPERVISOR,
        SALESBOARD,
        UNDERWRITING,
        CHATS,
        CHATBOT_CUSTOMESERVICE,
        ADMIN,
        LEGAL_EXPENSE,
        LEGAL_EXPENSE_CREATE_CASE,
        LEGAL_EXPENSE_MODIFY_CASE,
        LEGAL_EXPENSE_CREATE_EXPENSE,
        LEGAL_EXPENSE_MODIFY_EXPENSE,
        CHATBOT_DOCUMENT,
        LEGAL_COMPLAINTS,
        LEGAL_COMPLAINTS_ADMIN,
        OUTAGE_TRACKER,
        FHDINPUTFORM,
        'chatbot_customerservice_GLOBE',
        'chatbot_customerservice_AIL',
        'chatbot_customerservice_LND',
        'chatbot_customerservice_UA',
        'chatbot_customerservice_MILITARY',
        CHATBOT_LND_AGENT,
        'chatbot_lndagent_WS',
        'chatbot_lndagent_IND',
        FHDHIERARCHY,
        'fhdhierarchy_admin',
      ],
    };
    setUserInfo(info);
    return of<UserInfo>(info);
  }

  public getSchema(): string {
    return 'Bearer';
  }

  getAccessToken(): string {
    return 'eyJhbGciOiJSUzI1NiIsIng1dCI6IkxqN3pIYTAxSnYwZ2VxR3dEYUd4TkdzVmJfOCIsImtpZCI6IkxqN3pIYTAxSnYwZ2VxR3dEYUd4TkdzVmJfOCIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwZDBiNzhhZWFmYzk0Y2NiOWRiNGU0YjFlODViMDI1NyIsImNsaWVudF9pZCI6Ijk0NTM2NmMyMDJkMTQ5MmJiMTM4ODY1OGFlMjk0ZTZiIiwic2NvcGUiOlsiYWRkcmVzcyIsImVtYWlsIiwib2F1dGgiLCJvZmZsaW5lX2FjY2VzcyIsIm9wZW5pZCIsInBob25lIiwicHJvZmlsZSJdLCJzdWIiOiJieHNhbmRvdmFsIiwiYXV0aF90aW1lIjoxNzMzODY0Mzk2LCJpZHAiOiJTZWN1cmVBdXRoNzUiLCJpYXQiOjE3MzM4NjQ0MTIsImFtciI6WyJwYXNzd29yZCJdLCJuYmYiOjE3MzM4NjQ0MTIsImV4cCI6MTczMzg2ODAxMiwiaXNzIjoiaHR0cHM6Ly9kZXZzc28uZ2xvYmVsaWZlaW5jLmNvbS9TZWN1cmVBdXRoNzUiLCJhdWQiOiJodHRwczovL2RldnNzby5nbG9iZWxpZmVpbmMuY29tL1NlY3VyZUF1dGg3NSJ9.w9uNYoYVl1Usk05ace6vHvBeGohkGkay157UacgKdv04jzyBCbW_yTX5ZdsPlPkODtqwhBGLbxRRaRSpnVQtOM2vqKmDoPD0lIZSrPJJIautrNKQnCjQps8nxeec4AAw7_KSVwvrgdm90yDGceLEtMQisOD_POFAL8z3yHRkhXECHBkSmVVw2GLSkruXkdg-iX3VqyTLGJ8Fau2ow7w7W8_L0LLmUMsFq3yU1cY8_pcbHkoMRQD3nUNo5v4ctc7TgNB9ua532fBsFUoogRY1UcSmNLMjraxOnlqHgemS67u3b9CrXwqeRP6KhieHbSlaI_UamA26ePL0gVyD1JVcJg';
  }

  logout(): void {
    loggingOut();
    setUserAuthenticated(false);
    this.router.navigateByUrl('/logout');
  }
}
