import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from './core/auth/auth.service';
import { loggingOut$, userAuthenticated$ } from './core/state/user/user.store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.authService.checkSession();
    this.subscriptions.add(
      this.loggingOut$.subscribe((x) => {
        this.showForce = false;
        if (x === true) {
          setTimeout(() => {
            this.showForce = true;
          }, 60000);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  public authenticated$: Observable<boolean> = userAuthenticated$;
  public loggingOut$: Observable<boolean> = loggingOut$;
  public showForce = false;

  public forceLogout() {
    localStorage.clear();
    sessionStorage.clear();
    this.showForce = false;
    this.router.navigate(['logout']);
    location.reload();
  }
}
