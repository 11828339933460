import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private options: Partial<IndividualConfig> = {
    positionClass: 'giq-toast-top-right',
    enableHtml: true,
    tapToDismiss: true,
  };
  constructor(private toastr: ToastrService) {}

  public error(
    message: string,
    title: string | null = null,
    options: Partial<IndividualConfig> | null = null
  ): void {
    const finalOptions =
      options != null ? { ...this.options, ...options } : this.options;
    this.toastr.error(message, title ?? 'Error', finalOptions);
  }

  public warning(
    message: string,
    options: Partial<IndividualConfig> | null = null
  ): void {
    const finalOptions =
      options != null ? { ...this.options, ...options } : this.options;
    this.toastr.warning(message, undefined, finalOptions);
  }

  public success(
    message: string,
    options: Partial<IndividualConfig> | null = null
  ): void {
    const finalOptions =
      options != null ? { ...this.options, ...options } : this.options;
    this.toastr.success(message, undefined, finalOptions);
  }
}
