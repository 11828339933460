import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { withErrorMapping } from '../../core/errors/with-error-mapping';
import { IFHDHierarchy, IFHDHierarchyItem } from '../model/fhd-hierarchy';

@Injectable({
  providedIn: 'root',
})
export class FhdHierarchyService {
  baseURL = `${environment.apiUrl}`;  
  constructor(private httpClient: HttpClient) { }

  getAgents() {
    return this.httpClient
      .get<IFHDHierarchyItem[]>(
        `${this.baseURL}/fhdhierarchy`
      )
      .pipe(withErrorMapping);
  }

  updateFHDHierarchy(fhdHierarchyDetail: IFHDHierarchyItem[], deletedItems: String[]) {
    return this.httpClient.put
      <IFHDHierarchyItem[]>(
        `${this.baseURL}/fhdhierarchy/update`, {addedItems: fhdHierarchyDetail, deletedItems}
      )
      .pipe(withErrorMapping);
  }

  getFHDHierarchy() {
    return this.httpClient
      .get<IFHDHierarchy>(
        `${this.baseURL}/fhdhierarchy/list`
      )
      .pipe(withErrorMapping);
  }

}
