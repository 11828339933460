import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Crumb } from '../common/bread-crumbs/crumb';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { FHDReporteeDetail, FHDTaskItem } from './model/fhd-input-form';
import { FhdInputFormService } from './service/fhd-input-form.service';
import { catchError, debounceTime, distinctUntilChanged, finalize, throwError } from 'rxjs';
import { AlertService } from '../core/alerts/alerts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FHDTask, FHDTask2OutOf3Days } from './constants/task';
import { AuthService } from '../core/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';


@Component({
  selector: 'giq-fhd-input-form',
  templateUrl: './fhd-input-form.component.html',
  styleUrls: ['./fhd-input-form.component.scss']
})
export class FhdInputFormComponent {
  public crumbs: Crumb[] = [
    { name: 'Data Exchange', url: '/exchange' },
    { name: 'FHD Input Form', url: undefined },
  ];
  weekFilter = new FormControl();
  weekFilterList: string[] = [];
  taskList: FHDTaskItem[] = [];
  timeSlots: string[] = [];
  dayList: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thusday', 'Friday', 'Saturday', 'Sunday'];
  dayTaskDetails: any[] = [];
  userName: string = '';
  loggedInUserName: string = '';
  defaultSelectedTime = '05:00 AM';
  isReadyToLoad = false;
  reportees: FHDReporteeDetail[] = [];
  allReportees: FHDReporteeDetail[] = [];
  selectedReportee!: FHDReporteeDetail | undefined;
  searchControl = new FormControl('');
  optionalTaskList: FHDTaskItem[] = [];
  reporteeOptionalTaskList: FHDTaskItem[] = [];
  reporteeOptionalTaskResponse: FHDTaskItem[] = [];
  defaultOptionalTaskResponse: FHDTaskItem[] = [];
  showRightSideContent = false;
  reporteesOptionalTasks!: { [key: string]: boolean };
  defaultUserName = 'default';

  @ViewChild('viewReporteesDialog') viewReporteesDialog!: TemplateRef<any>;

  constructor(private fhdInputFormService: FhdInputFormService, private alertService: AlertService,
    private spinner: NgxSpinnerService, private authService: AuthService, private dialog: MatDialog) {

  }

  ngOnInit() {
    this.spinner.show();
    this.authService.getUserInfo().subscribe((info) => {
      this.userName = info.name;
      this.loggedInUserName = info.name;
    });
    this.weekFilter.valueChanges.subscribe((filter) => {
      this.updateTimesheetDetail(filter);
      this.getReportees(this.weekFilter.value);
    });
    this.getTask();
    this.updateWeekFilter();
    this.generateTimeSlots();
    this.searchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged(),
    ).subscribe(searchValue => {
      if (!searchValue) {
        this.reportees = [...this.allReportees];
      }
      else {
        this.reportees = this.allReportees.filter(reportee => {
          return reportee.displayName.toLowerCase().includes(searchValue.toLowerCase());
        });
      }
    });
  }

  getTask(userName?: string) {
    if (!userName) {
      this.isReadyToLoad = false;
    }
    this.fhdInputFormService.getTasks(userName ?? '').pipe(
      catchError((err: any) => {
        if (err.errorMessage) {
          this.alertService.warning(err.errorMessage);
        } else {
          this.alertService.warning('There was an error');
        }
        return throwError(() => err);
      })
    )
      .subscribe((response) => {
        if (userName === this.defaultUserName) {
          this.defaultOptionalTaskResponse = response;
        }
        else if (userName) {
          this.reporteeOptionalTaskResponse = response;
          this.reporteeOptionalTaskList = [];
          this.reporteesOptionalTasks = response.reduce((previousValue: any, currentValue) => {
            previousValue[currentValue.key] = true;
            return previousValue;
          }, {});
        }
        else {
          this.optionalTaskList = response;
          this.taskList = [...FHDTask, ...response];
          this.updateTimesheetDetail(this.weekFilter.value);
          this.getReportees(this.weekFilter.value);
        }
      });
  }

  getReportees(selectedRange: string) {
    this.fhdInputFormService.getReportees(selectedRange).pipe(
      catchError((err: any) => {
        if (err.errorMessage) {
          this.alertService.warning(err.errorMessage);
        } else {
          this.alertService.warning('There was an error');
        }
        this.spinner.hide();
        return throwError(() => err);
      })
    )
      .subscribe(response => {
        this.reportees = response;
        this.allReportees = response;
      });
  }

  updateWeekFilter() {
    let i = -5;
    while (i < 6) {
      const startDate = moment().subtract(i, 'weeks').startOf('isoWeek').format('MM-DD-YYYY');
      const endDate = moment().subtract(i, 'weeks').endOf('isoWeek').format('MM-DD-YYYY');
      const filterValue = `${startDate} to ${endDate}`;
      this.weekFilterList.push(filterValue);
      if (i === 0) {
        this.weekFilter.setValue(filterValue, { emitEvent: false });
      }
      i++;
    }
  }

  generateTimeSlots() {
    let startHour = 5;
    let startMinute = 0;
    const endHour = 24;
    const intervalMinutes = 15
    while (startHour < endHour || (startHour === 24 && startMinute === 0)) {
      let hour: any = startHour > 12 ? startHour - 12 : startHour || 12;
      hour = hour.toString().padStart(2, '0');
      const minute = startMinute.toString().padStart(2, '0');
      const meridian = startHour >= 12 && startHour < 24 ? 'PM' : 'AM';
      this.timeSlots.push(`${hour}:${minute} ${meridian}`);
      startMinute += intervalMinutes;
      if (startMinute >= 60) {
        startMinute -= 60;
        startHour += 1;
      }
    }
  }

  updateTimesheetDetail(selectedRange: string) {
    this.spinner.show();
    const userName = this.userName !== this.loggedInUserName ? this.userName : '';
    this.fhdInputFormService.getWeekTimesheet(userName, selectedRange).pipe(
      catchError((err: any) => {
        if (err.errorMessage) {
          this.alertService.warning(err.errorMessage);
        } else {
          this.alertService.warning('There was an error');
        }
        this.spinner.hide();
        return throwError(() => err);
      })
    )
      .subscribe(response => {
        const [startDate] = selectedRange.split('to');
        this.dayTaskDetails = [];
        let i = 0;
        while (i < 7) {
          const startMomentDate = moment(startDate, 'MM-DD-YYYY').add(i, 'day');
          const dayTaskDetail: any = {
            working_day: startMomentDate.format('YYYY-MM-DD'),
            week: Number(startMomentDate.format('YYYYww')),
            username: this.userName !== this.loggedInUserName ? this.userName : ''
          };
          const selectedPlannedValue = this.getRelatedValues(dayTaskDetail.working_day, 'PLANNED', response);
          const selectedActualValue = this.getRelatedValues(dayTaskDetail.working_day, 'ACTUAL', response);
          this.taskList.forEach(item => {
            let defaultValue = item.key === 'hours' ? '' : null;
            let planned = selectedPlannedValue?.[item.key] || defaultValue;
            let actual = selectedActualValue?.[item.key] || defaultValue;
            if ((item.key == 'start_time' || item.key == 'stop_time')) {
              if (!selectedPlannedValue) {
                planned = this.defaultSelectedTime;
              }
              if (!selectedActualValue) {
                actual = this.defaultSelectedTime;
              }
            }
            dayTaskDetail[item.key] = { planned, actual };
          });

          this.dayTaskDetails.push(dayTaskDetail);
          i++;
          if (i === 7) {
            this.isReadyToLoad = true;
            this.spinner.hide();
          }
        }
      });
  }

  getRelatedValues(date: string, type: string, data: any) {
    return data.find((item: any) => {
      return item.working_day === date && item.planned_actual === type;
    })
  }

  onSubmit() {
    if (this.dayTaskDetails.some(item => item.hours.planned?.includes('-') || item.hours.planned === '00.00' || item.hours.actual?.includes('-') || item.hours.actual === '00.00')) {
      this.alertService.error('Invalid time selection');
      return;
    }
    const payload = this.dayTaskDetails.filter(item => {
      return this.taskList.some(task => {
        if (['start_time', 'stop_time'].includes(task.key)) {
          return false;
        }
        return item[task.key].planned || item[task.key].actual;
      });
    });

    this.spinner.show();
    this.fhdInputFormService.updateTasks(payload)
      .pipe(
        catchError((err: any) => {
          if (err.errorMessage) {
            this.alertService.warning(err.errorMessage);
          } else {
            this.alertService.warning('There was an error');
          }
          return throwError(() => err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(() => {
        this.alertService.success('Saved');
      });
  }

  onViewReportees() {
    if (!this.defaultOptionalTaskResponse.length) {
      this.getTask(this.defaultUserName);
    }

    const dialogConfig = {
      minWidth: '950px',
      disableClose: true
    }
    this.dialog.open(this.viewReporteesDialog, dialogConfig);
  }

  updateHours(index: number, key: 'planned' | 'actual') {
    this.dayTaskDetails[index].hours[key] =
      this.getTotalHours(this.dayTaskDetails[index].start_time[key], this.dayTaskDetails[index].stop_time[key]);
  }

  getTotalHours(startTime: string, stopTime: string) {
    const startMinutes = this.timeToMinutes(startTime);
    const stopMinutes = this.timeToMinutes(stopTime);
    const diffMinutes = stopMinutes - startMinutes;
    const diffHours = Math.floor(diffMinutes / 60);
    let remainingMinutes = diffMinutes % 60;
    remainingMinutes += (remainingMinutes / 15) * 10;
    return `${String(diffHours).padStart(2, '0')}.${String(remainingMinutes).padStart(2, '0')}`;
  }

  timeToMinutes(time: string) {
    const [hourMinute, period] = time.split(' ');
    let [hours, minutes] = hourMinute.split(':').map(Number);
    if (period === 'PM' && hours !== 12) hours += 12;
    if (period === 'AM' && hours === 12) hours = 24;
    return hours * 60 + minutes;
  }

  getTotal(key: string, subKey: string) {
    return this.dayTaskDetails.reduce((acc, curr) => {
      if (key === 'hours') {
        if (!curr[key][subKey] || curr[key][subKey].includes('-') || curr[key][subKey] === '00.00') {
          return acc;
        }
        acc = acc === 0 ? '00.00' : acc;
        const [currHours, currMinutes] = curr[key][subKey].split('.').map(Number);
        const [accHours, accMinutes] = acc.split('.').map(Number);
        const diffMinutes = currMinutes + accMinutes;
        const diffHours = Math.floor(diffMinutes / 100);
        const remainingMinutes = diffMinutes % 100;
        acc = `${String(currHours + accHours + diffHours).padStart(2, '0')}.${String(remainingMinutes).padStart(2, '0')}`;
      }
      else {
        acc += curr[key][subKey] || 0;
      }
      return acc;
    }, 0);
  }

  updateMeetExpectation(index: number) {
    if (!this.dayTaskDetails.length) {
      return;
    }
    if (this.dayTaskDetails[index].hours?.actual) {
      let [actualHours] = this.dayTaskDetails[index].hours?.actual.split('.').map(Number);
      let count = 0;
      if (actualHours >= 8) {
        count++;
      }
      if (this.dayTaskDetails[index].demos?.actual >= 6) {
        count++;
      }
      if (this.dayTaskDetails[index].families_protected.actual >= 1) {
        count++;
      }
      this.dayTaskDetails[index].two_out_of_three.actual = count >= 2;

    }
    if (this.dayTaskDetails[index].hours?.planned) {
      let [plannedHours] = this.dayTaskDetails[index].hours?.planned.split('.').map(Number);
      let count = 0;
      if (plannedHours >= 8) {
        count++;
      }
      if (this.dayTaskDetails[index].demos?.planned >= 6) {
        count++;
      }
      if (this.dayTaskDetails[index].families_protected.planned >= 1) {
        count++;
      }
      this.dayTaskDetails[index].two_out_of_three.planned = count >= 2;
    }
  }

  onChangeUser(reportee?: FHDReporteeDetail) {
    this.isReadyToLoad = false;
    this.userName = reportee?.userName ?? this.loggedInUserName;
    this.selectedReportee = reportee;
    const optionalTaskResponse = reportee ? this.reporteeOptionalTaskResponse : this.optionalTaskList;
    this.taskList = [...FHDTask, ...optionalTaskResponse];
    this.updateTimesheetDetail(this.weekFilter.value);
    this.showRightSideContent = false;
  }

  onReporteeSelect(reportee: FHDReporteeDetail) {
    this.getTask(reportee.userName);
    this.selectedReportee = reportee;
    this.showRightSideContent = true;
  }

  onOptionalCategoryChange(checkboxChange: MatCheckboxChange, taskItem: FHDTaskItem) {
    if (this.reporteesOptionalTasks[taskItem.key] && !checkboxChange.checked) {
      this.reporteeOptionalTaskList.push({ ...taskItem, isRemoved: true });
    }
    else if (checkboxChange.checked && !this.reporteesOptionalTasks[taskItem.key]) {
      this.reporteeOptionalTaskList.push(taskItem);
    }
    else {
      this.reporteeOptionalTaskList = this.reporteeOptionalTaskList.filter(item => item.key != taskItem.key);
    }
  }

  saveOptionalTasks() {
    this.showRightSideContent = false;
    this.spinner.show();
    this.fhdInputFormService.updateOptionalTasks(this.selectedReportee?.userName, this.reporteeOptionalTaskList)
      .pipe(
        catchError((err: any) => {
          if (err.errorMessage) {
            this.alertService.warning(err.errorMessage);
          } else {
            this.alertService.warning('There was an error');
          }
          return throwError(() => err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(() => {
        this.alertService.success('Optional Events Saved');
      });
  }

  getTotal2OutOf3() {
    if (!this.dayTaskDetails.length) {
      return FHDTask2OutOf3Days[0];
    }
    const total = this.dayTaskDetails.reduce((acc, curr) => {
      if (curr.two_out_of_three.actual) {
        acc += 1;
      }
      return acc;
      return acc;
    }, 0);
    return FHDTask2OutOf3Days[total];
  }

  updateGAPTotal(key: string, index: number, type: 'planned' | 'actual') {
    if (['gap', 'rgap'].includes(key)) {
      const total = (this.dayTaskDetails[index].gap[type] || 0) + (this.dayTaskDetails[index].rgap[type] || 0)
      this.dayTaskDetails[index].total_gap[type] = total || '';
    }
  }
}
