import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export abstract class Base implements OnDestroy {
  public serviceDestroyed$ = new Subject();

  ngOnDestroy() {
    this.serviceDestroyed$.next(true);
    this.serviceDestroyed$.complete();
  }
}
