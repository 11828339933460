export const COLORS = [
    'red',
    'green',
    'blue',
    '#808000',
    '#800080',
    '#00008B',
    '#045F5F',
    '#555D50',
    '#560319',
    '#2B1B17',
    '#806517',
    '#3D3635',
    '#A55D35',
    '#A74AC7',
    '#4E5180',
    '#F2A2E8',
    '#C6AEC7'
];