<div class="headers">
  <h3 class="header-name">FHD Hierarchy</h3>
  <giq-bread-crumbs [crumbs]="crumbs"></giq-bread-crumbs>
</div>

<div class="main-container">
  <ngx-spinner [fullScreen]="false"></ngx-spinner>
  <div class="container">
    <div class="copy-action" *ngIf="isCopyEnabled">
      <button mat-button (click)="copyToAgents()">Copy to Agent Position</button>
    </div>
    <div cdkDropListGroup class="drag-drop-container">
      <div class="agent-container">
        <div class="agent-header">
          <span>Agent Owner({{ userName }})</span>
          <span>Reporting Week: {{ reportingDate }}</span>
          <mat-form-field appearance="outline" class="filter-input">
            <mat-label>Filter</mat-label>
            <input matInput [formControl]="searchControl" />
          </mat-form-field>
        </div>
        <div
          id="agent-list"
          cdkDropList
          [cdkDropListData]="agents"
          class="agent-list"
          (cdkDropListDropped)="drop($event)"
        >
          <div
            class="agent-item"
            *ngFor="let item of agents"
            cdkDrag
            [cdkDragDisabled]="isAgentsDisabled"
          >
            {{ item.currentStatus }} {{ item.agentNumber }} ({{
              item.agentName
            }})
          </div>
        </div>
      </div>
      <div class="hierarchy-container">
        <div class="hierarchy">
          <div class="access-header">
            <span> Agency Owner</span>
          </div>

          <div class="access-list-container">
            <div
              *ngFor="let parentDetail of agencyPosition; let i = index"
              class="access-list-items"
            >
              <div
                id="agencyList-{{ i }}"
                cdkDropList
                [cdkDropListData]="parentDetail.items"
                class="access-list"
                (cdkDropListDropped)="agencyDrop($event)"
              >
                <div
                  class="access-item"
                  *ngFor="let item of parentDetail.items"
                  cdkDrag
                  [style.color]="parentDetail.color"
                  (click)="isCopyEnabled=false"
                  [cdkDragDisabled]="isAgencyPositionDisabled"
                >
                  {{ item.currentStatus }} {{ item.agentNumber }} ({{
                    item.agentName
                  }})
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="hierarchy">
          <div class="access-header">
            <span> Admin </span>
          </div>

          <div class="access-list-container">
            <div
              *ngFor="let parentDetail of aaPosition; let i = index"
              class="access-list-items"
            >
              <div
                id="aaList-{{ parentDetail.parentId }}-{{
                  parentDetail.agencyId
                }}"
                cdkDropList
                [cdkDropListData]="parentDetail.items"
                class="access-list"
                (cdkDropListDropped)="aaDrop($event)"
              >
                <div
                  class="access-item access-list-item"
                  *ngFor="let item of parentDetail.items"
                  cdkDrag
                  [style.color]="parentDetail.color"
                  (click)="updateCopyItemState(item, parentDetail.agencyId)"
                  [cdkDragDisabled]="isAAPositionDisabled"
                >
                  {{ item.currentStatus }} {{ item.agentNumber }} ({{
                    item.agentName
                  }})
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="hierarchy">
          <div class="access-header">
            <span> AB </span>
          </div>

          <div class="access-list-container">
            <div
              *ngFor="let parentDetail of abPosition; let i = index"
              class="access-list-items"
            >
              <div
                id="abList-{{ parentDetail.parentId }}-{{
                  parentDetail.agencyId
                }}"
                cdkDropList
                [cdkDropListData]="parentDetail.items"
                class="access-list"
                (cdkDropListDropped)="abDrop($event)"
              >
                <div
                  class="access-item access-list-item"
                  *ngFor="let item of parentDetail.items"
                  cdkDrag
                  [style.color]="parentDetail.color"
                  (click)="isCopyEnabled=false"
                  [cdkDragDisabled]="isABPositionDisabled"
                >
                  {{ item.currentStatus }} {{ item.agentNumber }} ({{
                    item.agentName
                  }})
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="hierarchy">
          <div class="access-header">
            <span> Agents </span>
          </div>

          <div class="access-list-container">
            <div
              *ngFor="let parentDetail of agentPosition; let i = index"
              class="access-list-items"
            >
              <div
                id="agentList-{{ parentDetail.parentId }}-{{
                  parentDetail.agencyId
                }}"
                cdkDropList
                [cdkDropListData]="parentDetail.items"
                class="access-list"
                (cdkDropListDropped)="agentDrop($event)"
              >
                <div
                  class="access-item access-list-item"
                  *ngFor="let item of parentDetail.items"
                  cdkDrag
                  [style.color]="parentDetail.color"
                  (click)="isCopyEnabled=false"
                  [cdkDragDisabled]="isAgentsPositionDisabled"
                >
                  {{ item.currentStatus }} {{ item.agentNumber }} ({{
                    item.agentName
                  }})
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-action">
      <button mat-button (click)="onRecallClick()">Recall</button>
      <button mat-button (click)="onSubmitClick()">Submit</button>
    </div>
  </div>
</div>
